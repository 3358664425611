<template>
  <v-main>
    <v-container>

      <!-- quick and dirty -->
      <div v-for="course in courseSummaries" :key="course.id" class="mt-8">
        <div class="text-h5">{{ course.name }}</div>
        <div v-for="(lesson, i) in course['lessons']" :key="lesson.id" class="mt-3">
          <router-link :to="{ name: 'LessonEditor', params: { lessonId: lesson.id } }">{{ lesson.title }}</router-link>
        </div>
      </div>

    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "CourseEditor",
  computed: {
    courseSummaries() {
      return this.$store.state.applicationData["courses/summary"];
    }
  },
  mounted() {
    this.$store.dispatch("getApplicationData", "courses/summary");
  }
};
</script>

<style scoped>

</style>